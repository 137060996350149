<template>
  <section id="stock-value">
    <b-card>
      <b-row>
        <b-col xl="2" md="2">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="2" md="2">
          <b-form-radio-group name="radios-btn-default" button-variant="primary" buttons class="btn-block"
            v-model="selectedSource" :options="optionsSource" @change="changeSource" />
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col xl="12">
        <b-overlay :show="showStockValueAmounts" spinner-variant="primary" variant="transparent" blur="3px"
          rounded="sm">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Fixed stock value
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer"
                  id="popover-fixed-stock-value" />
              </h4>
              <b-popover target="popover-fixed-stock-value" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <ECharts ref="stockValueAmountsChart" :options="stockValueAmountsChart"
              style="width: 100%; height: 500px;" />
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
    <b-overlay :show="showStockList" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-row>
        <b-col xl="10">
          <b-card no-body>
            <b-card-header>
              <h4 class="mb-0">
                Stock list
                <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-stock-list" />
              </h4>
              <b-popover target="popover-stock-list" triggers="hover" placement="bottom">
                <span>No data</span>
              </b-popover>
            </b-card-header>
            <b-table striped hover responsive class="position-relative" :current-page="stockListTable.currentPage"
              :items="stockListTable.items" :fields="stockListTable.fields" :sort-by.sync="stockListTable.sortBy"
              :sort-desc.sync="stockListTable.sortDesc" :sort-direction="stockListTable.sortDirection"
              :filter="stockListTable.filter" :filter-included-fields="stockListTable.filterOn"
              @sort-changed="sortChangedStockListTable" />
            <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
              <!-- pagination -->
              <div>
                <b-pagination v-model="stockListTable.currentPage" :total-rows="stockListTable.totalRows" first-number
                  last-number prev-class="prev-item" next-class="next-item" class="mb-0"
                  @change="handleStockListTablePageChange">
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col xl="2">
          <b-card no-body>
            <b-card-body class="d-flex justify-content-between align-items-center">
              <div class="truncate">
                <h2 class="mb-25 font-weight-bolder">
                  {{ totalValue | formatNumber }}
                </h2>
                <span>Total Value</span>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-overlay>
  </section>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BPopover,
  BTable,
  BPagination,
  BRow,
  BCol,
  BFormRadioGroup,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
} from 'bootstrap-vue';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';
import ECharts from 'vue-echarts';

import 'echarts/lib/chart/line';
import 'echarts/lib/component/tooltip';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BCard,
    BCardBody,
    BCardHeader,
    BPopover,
    BTable,
    BPagination,
    BRow,
    BCol,
    BFormRadioGroup,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    flatPickr,
    ECharts,
  },
  data() {
    return {
      showStockValueAmounts: true,
      showStockList: true,
      selectedSource: 'eazybusiness',
      optionsSource: [
        { text: 'H+H', value: 'eazybusiness' },
        { text: 'Wecology', value: 'Mandant_3' },
      ],
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      totalValue: 0.0,
      queryParamsStockValueAmounts: {},
      queryParamsStockList: {},
      stockValueAmountsChart: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            let result = params[0].name + '<br/>';
            params.forEach(param => {
              result += param.value ? `${param.marker} ${param.seriesName}: ${parseFloat(param.value).toLocaleString('de-DE')} €<br/>` : '';
            });
            return result;
          }
        },
        legend: {},
        xAxis: {
          type: 'category',
          data: [],
          axisTick: {
            alignWithLabel: true
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: function (value) {
              return value.toLocaleString('de-DE');
            }
          }
        },
        series: [],
      },
      stockListTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'total',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'sku', label: 'sku', sortable: true },
          {
            key: 'price',
            label: 'price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'qty',
            label: 'qty',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'total',
            label: 'total',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `€ ${this.$formatNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const currentDate = new Date();
    const dateRange = this.dateRange.split(' to ');

    this.queryParamsStockValueAmounts.from_date = dateRange[0];
    this.queryParamsStockValueAmounts.to_date = dateRange[1];
    this.queryParamsStockValueAmounts.dbname = this.selectedSource;

    this.queryParamsStockList.dbname = this.selectedSource;
    this.queryParamsStockList.date = `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-${currentDate.getDate()}`;

    try {
      await this.getStockList();
      await this.getStockValueAmounts();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getStockValueAmounts() {
      this.showStockValueAmounts = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/stock-value-amounts/`, this.queryParamsStockValueAmounts);
        const results = response.data;
        let dates = results.map(item => `${new Date(item.created).getMonth() + 1}.${new Date(item.created).getFullYear()}`);
        dates = [...new Set(dates)];
        this.stockValueAmountsChart.xAxis.data = dates;
        this.stockValueAmountsChart.series[0] = {
          name: 'Purchasing',
          type: 'line',
          areaStyle: {},
          data: results
            .filter(item => item.type === "purchasing")
            .map(item => item.amount),
        };
        this.stockValueAmountsChart.series[1] = {
          name: 'Sales',
          type: 'line',
          areaStyle: {},
          data: results
            .filter(item => item.type === "sales")
            .map(item => item.amount),
        };
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showStockValueAmounts = false;
      }
    },
    async getStockList() {
      this.showStockList = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/stock-list/`, this.queryParamsStockList);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.stockListTable.items = results;
          this.stockListTable.totalRows = results[0].count * 2;
          this.totalValue = results[0].total_amount;
        }
        else {
          this.stockListTable.items = [];
          this.stockListTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showStockList = false;
      }
    },
    async changeSource() {
      this.queryParamsStockValueAmounts.dbname = this.selectedSource;
      this.queryParamsStockList.dbname = this.selectedSource;
      await this.getStockList();
      await this.getStockValueAmounts();

      this.$nextTick(() => {
        this.$refs.stockValueAmountsChart.refresh();
      });
    },
    async changeDateRange() {
      if (this.dateRange != this.oldDateRange) {
        const dateRange = this.dateRange.split(' to ');
        this.queryParamsStockValueAmounts.from_date = dateRange[0];
        this.queryParamsStockValueAmounts.to_date = dateRange[1];
        await this.getStockValueAmounts();
        this.oldDateRange = this.dateRange;

        this.$nextTick(() => {
          this.$refs.stockValueAmountsChart.refresh();
        });
      }
    },
    async sortChangedStockListTable(value) {
      if (value.sortDesc === true) {
        this.queryParamsStockList.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsStockList.ordering = value.sortBy;
      }
      await this.getStockList();
    },
    async handleStockListTablePageChange(value) {
      this.queryParamsStockList.page = value;
      await this.getStockList();
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
